<template>
  <div>
    <p style="text-align: right" class="is-size-4 has-text-grey-dark">
      {{ $t('Resource Work Schedule Loader') }}
    </p>
    <keep-alive>
      <result-tables
        v-if="successList.length > 0 || errorList.length > 0"
        :success-data="dynamicData"
        :error-data="dynamicErrors"
        :success-columns="successColumns"
        :error-columns="errorColumns"
      />
    </keep-alive>
    <b-button type="is-info" @click="showHelp = !showHelp">
      {{ showHelp ? $t('Hide guide') : $t('Show guide') }}
    </b-button>
    <br />
    <b-message
      :active.sync="showHelp"
      :title="$t('Using the Resource Work Schedule & Shift Loader')"
      style="padding-top: 10px"
      class="is-info"
    >
      <span v-html="$t('Resource Work Schedule Loader Help1')"></span>
      <img src="../../../assets/img/ExcelTextHelp.png" style="width: 500px" />
      <span v-html="$t('Resource Work Schedule Loader Help2')"></span>
      <img src="../../../assets/img/ExcelDateHelp.png" style="width: 500px" />
      <span v-html="$t('Resource Work Schedule Loader Help3')"></span>
    </b-message>
    <file-loader
      v-if="!error"
      :url="loaderURL"
      :customer="customer"
      :emit-type="emitType"
      :environment="environment"
      @upload-started="uploadStarted()"
    />
    <span v-if="error">{{ error }}</span>
    <br />
    <a
      :href="csvTemplate"
      download="resource-work-schedule-template.csv"
      class="has-text-link"
      >{{ $t('Download CSV Template') }}</a
    >
    <div v-if="retrieved">
      <p>Successful: {{ dynamicData.length }}</p>
      <p>Errors: {{ errorList.length }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import FileLoader from './FileLoader';
import { eventBus } from '@/events/events';
import ResultTable from './ResultTable';

export default defineComponent({
  name: 'ResourceWorkScheduleLoader',
  components: {
    FileLoader,
    ResultTables: ResultTable,
  },
  props: {
    environment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emitType: 'resourceWorkScheduleLoadResults',
      showHelp: true,
      dynamicData: [],
      formattedSuccessList: [],
      retrieved: false,
      error: null,
      errorList: [],
      result: null,
      successList: [],
      successColumns: [
        {
          field: 'resourceId',
          label: 'Resource ID',
          width: '60',
          numeric: false,
        },
        {
          field: 'itemsUpdated',
          label: 'Successful',
          width: '30',
          numeric: true,
        },
        {
          field: 'itemsFailed',
          label: 'Failed',
          width: '30',
          numeric: true,
        },
      ],
      errorColumns: [
        {
          field: 'resourceId',
          label: 'Resource ID',
          width: '50',
          numeric: false,
        },
        {
          field: 'title',
          label: 'Title',
          width: '100',
          numeric: false,
        },
        {
          field: 'detail',
          label: 'Detail',
          width: '100',
          numeric: false,
        },
        {
          field: 'status',
          label: 'Status',
          width: '40',
          numeric: false,
        },
      ],
    };
  },
  computed: {
    loaderURL() {
      return this.getURL();
    },
    csvTemplate() {
      return require('@/assets/csv/WorkSchedTemplate.csv').default;
    },
  },
  created() {
    eventBus.on(this.emitType, (data) => {
      this.emptyArray(this.successList);
      this.emptyArray(this.errorList);
      this.emptyArray(this.dynamicData);
      this.retrieved = true;
      this.successList = data;
      this.buildErrorData(data);
      this.buildDynamicData(data);
      this.showHelp = false;
    });
  },
  beforeDestroy() {
    eventBus.off(this.emitType);
  },
  mounted() {
    this.emptyArray(this.successList);
    this.emptyArray(this.errorList);
    this.emptyArray(this.dynamicData);
  },
  methods: {
    uploadStarted() {
      this.emptyArray(this.successList);
      this.emptyArray(this.errorList);
      this.emptyArray(this.dynamicData);
    },
    buildDynamicData(data) {
      let retVal = [];
      data.forEach((row) => {
        let dynamicValue = {};
        dynamicValue.resourceId = row.resourceId;
        dynamicValue.itemsUpdated = row.itemsUpdated;
        dynamicValue.itemsFailed = row.itemsFailed;
        retVal.push(dynamicValue);
      });
      this.dynamicData = retVal;
    },
    buildErrorData(data) {
      let retVal = [];
      data.forEach((row) => {
        if (row.errors) {
          row.errors.forEach((error) => {
            let dynamicValue = {};
            dynamicValue.resourceId = row.resourceId;
            dynamicValue.title = error.title;
            dynamicValue.status = error.status;
            dynamicValue.detail = error.detail;
            retVal.push(dynamicValue);
          });
        }
      });
      this.dynamicErrors = retVal;
    },

    getURL() {
      const baseURL = this.$configData.dataLoaders.baseURL;
      const path = this.$configData.dataLoaders.resourceWorkScheduleLoader.path;
      if (!baseURL || !path) {
        this.error = 'No configuration found for loader';
        return null;
      } else {
        return baseURL + path;
      }
    },
  },
});
</script>
